import { Tabs } from 'lynkco-up-core';
import { Tab } from 'lynkco-up-core/dist/types/components/Tabs';
import { Button, GlobalContext, Input, Table, Tbody, Td, Th, Thead, Tr } from 'lynkco-up-core';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { EditMarketInfo, GetMarketInfo, getCountryByCode } from '../../services/bookings/types';
import editMarket from '../../services/markets/editMarkets';
import getMarkets from '../../services/markets/getMarkets';
import { PageHeading } from '../../components';
import { handleAction } from '../HistoryLog/historyLogService';
import { ActionStatus } from '../HistoryLog/types';

const EnergyFees = () => {
  const { alert } = useContext(GlobalContext);

  async function fetchMarketInfos() {
    setIsLoading(true);
    try {
      const fetchedMarketInfos: GetMarketInfo[] = await getMarkets();
      setMarkets(fetchedMarketInfos);
    } catch (error) {
      alert.show('Failed to fetch markets:', 'error');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchMarketInfos();
  }, []);

  const [markets, setMarkets] = useState<GetMarketInfo[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [oldValue, setOldValue] = useState<number>();

  const submitFuelChange = () => {
    markets.map(market => {
      if (market.isChanged) {
        const editModel: EditMarketInfo = {
          fuel: market.fuelPerLiter,
          electricPerKwh: market.electricPerKwh,
          buffer: market.buffer,
          currency: market.currency,
          maxCostLimitPerBooking: market.maxCostLimitPerBooking,
          vat: market.vat,
          serviceFees: market.serviceFees,
        };
        editMarket(editModel, market.market)
          .then(() => {
            fetchMarketInfos();
            alert.show('Successfully updated market', 'success');
            handleAction(ActionStatus.MODIFYING_FUEL_FEE, [
              { market: market.market },
              { newValue: editModel.fuel },
              { oldValue },
            ]);
          })
          .catch(() => {
            fetchMarketInfos();
            alert.show('Unable to update market', 'error');
          });
      }
    });
  };

  const handleFeeChange = (market: string, energyType: EnergyType, newFee: number) => {
    const updatedData = markets.map(item => {
      if (item.market === market) {
        if (!oldValue) {
          setOldValue(item.fuelPerLiter);
        }
        item.isChanged = true;
        switch (energyType) {
          case EnergyType.FUEL:
            return { ...item, fuelPerLiter: newFee };
          case EnergyType.ELECTRICITY:
            return { ...item, electricPerKwh: newFee };
        }
      }
      return item;
    });
    setMarkets(updatedData);
  };

  const FuelTable = () => {
    return (
      <Table extraClasses="min-w-max">
        {renderTableHeader('Fuel Cost / Litre')}
        {renderFuelTableBody()}
      </Table>
    );
  };

  const ElectricTable = () => {
    return (
      <Table extraClasses="min-w-max">
        {renderTableHeader('Electricity Cost / kWh')}
        {renderElectricTableBody()}
      </Table>
    );
  };

  const renderTableHeader = (name: string) => {
    return (
      <Thead>
        <Tr>
          <Th>
            <span className="font-medium text-sm text-neutral-400">Country Name</span>
          </Th>
          <Th>
            <span className="font-medium text-sm text-neutral-400">Currency</span>
          </Th>
          <Th>
            <span className="font-medium text-sm text-neutral-400">{name}</span>
          </Th>
        </Tr>
      </Thead>
    );
  };

  const renderFuelTableBody = () => {
    return (
      <Tbody>
        {markets?.map(item => (
          <Tr key={item.market}>
            <Td extraClasses="border-bottom px-4 py-2 font-bold">{getCountryByCode(item.market)}</Td>
            <Td extraClasses="border-bottom px-4 py-2 font-medium">{item.currency}</Td>
            <Td extraClasses="border-bottom px-4 py-2" align="center">
              <div className="flex items-center">
                <Input
                  type="number"
                  defaultValue={item.fuelPerLiter.toFixed(2) ? item.fuelPerLiter.toFixed(2) : ''}
                  onChange={e => handleFeeChange(item.market, EnergyType.FUEL, +e.currentTarget.value)}
                />
              </div>
            </Td>
          </Tr>
        ))}
      </Tbody>
    );
  };

  const renderElectricTableBody = () => {
    return (
      <Tbody>
        {markets?.map(item => (
          <Tr key={item.market}>
            <Td extraClasses="border-bottom px-4 py-2 font-bold">{getCountryByCode(item.market)}</Td>
            <Td extraClasses="border-bottom px-4 py-2 font-medium">{item.currency}</Td>
            <Td extraClasses="border-bottom px-4 py-2" align="center">
              <div className="flex items-center">
                <Input
                  type="number"
                  defaultValue={item.electricPerKwh.toFixed(2) ? item.electricPerKwh.toFixed(2) : ''}
                  onChange={e => handleFeeChange(item.market, EnergyType.ELECTRICITY, +e.currentTarget.value)}
                />
              </div>
            </Td>
          </Tr>
        ))}
      </Tbody>
    );
  };

  const tabs: Tab[] = [
    {
      name: 'Fuel Fees',
      content: <FuelTable />,
      isActive: true,
    },
    {
      name: 'Electricity Fees',
      content: <ElectricTable />,
    },
  ];

  return (
    <div className="min-h-screen">
      <Helmet>
        <title>Energy fees - Car Sharing</title>
      </Helmet>
      <PageHeading
        title="Energy Prices"
        description="Two tabs, Fuel and Electricity showing the fees for each country."></PageHeading>
      <div className="px-3.5">
        {isLoading ? (
          <div className="text-center mt-4 ">Loading...</div>
        ) : (
          <Tabs tabsName="energy-prices" tabs={tabs} />
        )}
        <Button
          onClick={submitFuelChange}
          variant="primary"
          extraClasses="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500">
          Submit
        </Button>
      </div>
    </div>
  );
};

export default EnergyFees;

enum EnergyType {
  FUEL,
  ELECTRICITY,
}
